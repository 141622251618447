<template>
  <div
    :id="dropdownId"
    class="cursor-pointer p-1 text-gray-400"
    :class="horizontal ? 'w-8' : 'w-4'"
    @click="toggleDropdown()"
  >
    <DropdownAlign :on="droppedDown">
      <template #trigger>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          :class="`${horizontal ? 'rotate-90' : ''} hover:text-white ${iconSize}`"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
        </svg>
      </template>

      <template #dropdown>
        <DropdownAdvance
          v-if="droppedDown && advance"
          :items="items"
          :max-text-length="15"
          :use-parent-container="true"
          dropdown-width="135px"
          custom-classes="right-0"
          custom-styles="margin-left: -135px"
          @close="droppedDown = false"
          @clicked="handleAdvanceAction($event)"
        />
        <DropdownBase
          v-else-if="droppedDown"
          :items="items"
          :sort-options="sortOptions"
          :custom-styles="customStyle ? 'margin-left: 61% !important; margin-top: -10px;' : null"
          @clicked="handleAction($event)"
          :use-parent-container="true"
          :sort-key="sortKey"
          :sort-order="sortOrder"
        />
      </template>
    </DropdownAlign>
  </div>
</template>
<script>
import DropdownAlign from '@/components/dropdown/DropdownAlign.vue';
import DropdownBase from '@/components/dropdown/DropdownBase.vue';
import DropdownAdvance from '@/components/dropdown/DropdownAdvance.vue';
export default {
  components: {
    DropdownAlign,
    DropdownBase,
    DropdownAdvance
  },
  props: {
    items: Array,
    xAnchor: {
      type: String,
      default: 'right'
    },
    cellInfo: Object,
    globalEmit: {
      type: Boolean,
      default: true
    },
    iconSize: {
      type: String,
      default: 'h-4'
    },
    horizontal: {
      type: Boolean,
      default: false
    },
    returnId: {
      type: Boolean,
      default: false
    },
    dropdownWidth: {
      type: String,
      default: '200px'
    },
    customStyle: {
      type: Boolean,
      default: false
    },
    advance: {
      type: Boolean,
      default: false
    },
    sortOptions: { type: Boolean, default: false },
    sortKey: { type: String, default: 'text' },
    sortOrder: { type: String, default: 'asc' }
  },
  data() {
    return {
      droppedDown: false,
      dropdownId: `table_dropdown_${Math.random()}`
    };
  },

  methods: {
    close(e) {
      const drop = document.getElementById(this.dropdownId);
      if (drop && !drop.contains(e.target) && this.droppedDown && e.target.id !== this.dropdownId) {
        this.droppedDown = false;
      }
    },
    toggleDropdown: function () {
      this.droppedDown = !this.droppedDown;
    },
    handleAction: function (id) {
      const item = this.items.find(x => x.id == id);
      if (this.globalEmit) {
        this.$eventHub.$emit(item.event, this.returnId ? id : this.cellInfo);
      } else {
        this.$emit(item.event, this.returnId ? id : this.cellInfo);
      }
    },
    handleAdvanceAction(item) {
      if (item.event) {
        this.$emit(item.event, item.id);
      }
    }
  },

  mounted() {
    document.addEventListener('click', this.close);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.close);
  }
};
</script>
